import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import noDataImage from "../../assets/nodata.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLimit, changePage } from "../../redux/table/table";
import './style.scss'
const Table = ({
  data = [],
  column = [],
  loading = true,
  redirect = "_id",
  totalPages =1,
  path =""
}) => {

  // states

  const [showSorting, setShowSorting] = useState(false);
  const [list, setList] = useState([]);
  const {page,limit} = useSelector(state=>state.tablePagination)
  const dispatch = useDispatch();
  const {pathname} = useLocation()
  const navigate  = useNavigate()

  useEffect(() => {
    if (data.length >0) {
      setList(data);
    }
  }, [data]);

useEffect(()=>{
  dispatch(changeLimit(limit))
  dispatch(changePage(page))
},[pathname])

  const handleSort = (order, property, type) => {
    try {
      setShowSorting(false);
      if (!list || list.length === 0) {
        return;
      }
  
      const sortedArr = [...list];
  
      sortedArr.sort((a, b) => {
        if (type === "string") {
          const valueA = a[property];
          const valueB = b[property];
          return order === "asc" ?  valueA.localeCompare(valueB) :  valueB.localeCompare(valueA);
        }
        return 0; 
      });
      setList(sortedArr);
    } catch (error) {
      console.error("Error sorting list:", error);
    }
  };
  

  const handlePrevPage = () => {
    if (page <= 1) {
      return;
    } else {
      dispatch(changePage(page-1))
    }
  };

  const handleNextPage = () => {
   
    if (page === totalPages) {
      return;
    } else {
      dispatch(changePage(page+1))
    }
  };

  return (
    <div className="table-container">
      <table className="main-table">
        <thead>
          <tr>
            <th style={{minWidth:60}}>S.No</th>
            {column.map((val, i) => {
              return val.sorting ? (
                <th key={i} className="sorting-row">
                  {val?.headerName}
                  <AiFillCaretDown
                    style={{
                      transform: showSorting ? "rotate(180deg)" : "",

                      transition: "0.3s",
                    }}
                    onClick={() => setShowSorting(!showSorting)}
                  />
                  <div
                    className="sorting-option"
                    style={{
                      height: showSorting ? "100px" : "0px",
                    }}
                  >
                    <li
                      key={"asc"}
                      onClick={() => handleSort("asc", val?.property, val?.type)}
                    >
                      {" "}
                      Sort A- Z
                    </li>
                    <li
                      key={"dsc"}
                      onClick={() => handleSort("desc",val?.property, val?.type)}
                    >
                      Sort Z- A
                    </li>
                  </div>
                </th>
              ) : (
                <th style={{minWidth:val.minWidth}} key={i}>{val?.headerName}</th>
              );
            })}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {list
            ?.map((val, i) => (
              <tr key={i}>
                <td>{limit * (page - 1) + i + 1}</td>
                {column.map((content, i) => (
                  <td key={i}>
                    {content.type === "image" ? (
                      <div className="user-pic">
                        {val.dp ? (
                          <img src={val.dp} alt="" />
                        ) : (
                          <img src="/images/profile.jpg" alt="" />
                        )}
                      </div>
                    ) : (
                      <span>{val[content.property] || "-"}</span>
                    )}
                  </td>
                ))}
                <td style={{minWidth:100}}>
           
                    <button className="btn-know" onClick={()=>navigate(`${path}/${val[redirect]}`)}>View</button>
                  
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* No data image */}
      {!loading && list.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "30%",
            }}
            src={noDataImage}
            alt="no data"
          />
        </div>
      )}

      {/* Pagination */}
      <div className="load-more-div">
        <div className="rows-div">
          <select
            name="rowsNumber"
            onChange={(e) => dispatch(changeLimit((Number(e.target.value))))}
            style={{
              width: "60px",
              height: "30px",
              marginRight: "20px",
            }}
          >
            <option value="25">300</option>
            <option value="50">600</option>
            <option value="100">1000</option>
          </select>
        </div>
        <div className="page-number-div">
          <button className="page-btn" onClick={handlePrevPage}>
            Prev
          </button>
          {page} of {totalPages}
          <button className="page-btn" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
