import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authAxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const addAmountToUserAction = createAsyncThunk(
    'add-amount',
    async (data, { rejectWithValue }) => {
        try {
         
            const res = await authAxios.post(`/add/amount`,{...data?.formData} )
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const addAmountToUserSlice = createSlice({
    name:'add-amount',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(addAmountToUserAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(addAmountToUserAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(addAmountToUserAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const addAmountToUserReducer = addAmountToUserSlice.reducer