import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {Pie} from 'react-chartjs-2'
ChartJS.register(ArcElement, Tooltip, Legend);
const CampaignPieChart = ({totalCampaign,liveCampaign,completedCampaign}) => {
    const options ={}
    const data = {
        labels: ["Total Campaign", "Live Campaign","Completed Campaign"],
        datasets: [
          {
            data: [totalCampaign, liveCampaign, completedCampaign],
            backgroundColor: ["#D52DB7", "#FF2E7E","black"],
            borderColor: ["white", "white","white"],
            borderWidth: [1],
          },
        ],
      };
  return (
    <div style={{
        width:"100%"
    }}>
         <Pie data={data} options={options}></Pie>
    </div>
  )
}

export default CampaignPieChart