import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import {useDispatch, useSelector} from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { getWeeklyRegistrationAction } from '../../redux/home/getWeeklyRegistration';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const RegistrationBarChart = () => {
  const dispatch = useDispatch();
  const {data:registrationData} = useSelector(state=>state.weeklyRegistration)
    const labels = registrationData?.map((val)=>val.date);
    useEffect(()=>{
      dispatch(getWeeklyRegistrationAction())
    },[])
    const options = {
        responsive: true,
      };
      const data = {
        labels,
        datasets: [
          {
            label: "Registartion Trend",
            data: registrationData?.map((val) =>val.totalUsers),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }]}
  return (
    <div style={{
        width:"100%",
        height:"100%"
    }}>
        <Bar options={options} data={data}>

        </Bar>
    </div>
  )
}

export default RegistrationBarChart