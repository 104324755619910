import React from 'react'
import './style.scss'

const CoverLoader = () => {
  return (
    <div style={{position:"fixed", top:0 ,display:"flex",alignItems:"center",justifyContent:"center", background:"white", minHeight:"100vh",right:0,width:"100%",zIndex:1000}}>
<div class="loader"></div>
    </div>
  )
}

export default CoverLoader