import { createSlice } from "@reduxjs/toolkit";

const manageBodyContent = createSlice({
  name: "body-css",
  initialState: {
    marginLeft: false, // Corrected typo here
  },
  reducers: {
    setBodyMargin: (state, action) => {
      state.marginLeft = action.payload; // Corrected typo here
    },
  },
});

export const { setBodyMargin } = manageBodyContent.actions;
export default manageBodyContent.reducer;
