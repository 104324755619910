import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { MdEditDocument } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { GoPackage } from "react-icons/go";
import { BiSolidOffer } from "react-icons/bi";

const Meta = () => {
    const navigate =useNavigate()
  return (
    <div className='container'>
        <Card>
        <CardContent>
          <h3>Meta Features</h3>
        </CardContent>
      </Card>
      <br />
        <Stack direction={"row"} gap={2}>
        <Card sx={{
            maxWidth:200
        }}>
            <CardContent sx={{
                textAlign:"center"
            }}>
                <GoPackage style={{
                    fontSize:"2rem"
                }}/>
                <Typography>Manage Packages</Typography>
                <Button onClick={()=>navigate('/packages')} startIcon ={<MdEditDocument/>} >Manage</Button>
            </CardContent>
        </Card>
        <Card sx={{
            maxWidth:400
        }}>
            <CardContent sx={{
                 textAlign:"center"
            }}>
            <BiSolidOffer style={{
                    fontSize:"2rem"
                }}/>
                <Typography>Manage PromoCodes</Typography>
                <Button onClick={()=>navigate('/packages')} startIcon ={<MdEditDocument/>} >Manage</Button>
            </CardContent>
        </Card>
        </Stack>
        
    </div>
  )
}

export default Meta