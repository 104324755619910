import React, { useEffect, useState } from "react";
import { Button, Stack, TextField, Box, Card, CardContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from '../../components/table/Table'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTransactionAction } from "../../redux/payment/getAllTransactions";
import { filterTransactionAction } from "../../redux/payment/ filterTransaction";
import Loader from '../../components/Loader'
import CoverLoader from '../../components/cover-loader/CoverLoader'
import Toast from '../../components/Toast'
import { userPaymentTransactionAction } from "../../redux/user/userTransaction";
const UserTransaction = () => {
  const [formaData, setFormData] = useState({ });
  const[list,setList] = useState([]);
  const[snake,setSnake] =useState(false)
  const [totalPages,setTotalPages] = useState(1);

  const {userId} = useParams();

//   redux
  const dispatch = useDispatch();
  const { data, loading:filterLoading,status, message } = useSelector(
    (state) => state.userPaymentTransaction
  );
  const {limit,page} = useSelector(state=>state.tablePagination)


  useEffect(() => {
    if(Object.keys(formaData).length === 0 || Object.values(formaData).length === 0){
      dispatch(userPaymentTransactionAction({limit,page,userId}));
    }

  }, [limit,page]);

useEffect(()=>{
  if(data){
    setList(data?.filterPayment)
    setTotalPages(data?.totalPages)
  }
},[data])

  const handleFilter = async () => {
    if(Object.keys(formaData).length === 0){
      return
    }
 const filter = await  dispatch(filterTransactionAction({formaData,userId,limit,page}));
 setList(filter?.payload?.data?.filterPayment)
 setTotalPages(filter?.payload?.data?.totalPages)
 setSnake(true)
  };

  const handlereset =async()=>{
    await dispatch(getAllTransactionAction())
    setFormData({})
  }

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const columns = [
    {
      property: "userId",
      headerName: "User Id",
      minWidth: 250,
    },
    {
      headerName: "Wallet Ded.",
      property: "walletDeductedAmount",
      minWidth: 120,
      
    },
    {
      property: "amount",
      headerName: "Amount",
      minWidth: 120,
    },
    {
      property: "status",
      headerName: "Payment Status",
      minWidth: 150,
    },
    {
      headerName: "Method",
      property: "paymentMethod",
      minWidth: 120,
      flex: 1,
    },
    {
      property: "currency",
      headerName: "Currency",
      minWidth: 100,
    },
    {
      property: "merchantTransactionId",
      headerName: "Merchant TId",
      minWidth: 230,
    },

    {
      property: "pgTransactionId",
      headerName: "Gateway TId",
      minWidth: 230,
    },

    

    {
      property: "campaignId",
      headerName: "Campaign Id",
      minWidth: 200,
    },

    {
      property: "type",
      headerName: "Type",
      minWidth: 120,
    },
    {
      property: "timestamp",
      headerName: "Date & Time",
      minWidth: 300,
    },
  ];

  return (
    <>
   
    <div className="container">
      <Card>
        <CardContent>
          <h3>User Payment Transactions</h3>
        </CardContent>
      </Card>
      <br />
      <Stack
        sx={{
          width: "100%",
          background: "white",
          paddingInline: "20px",
          paddingBlock: "20px",
        }}
        direction={"row"}
        gap={2}
        alignItems={"end"}
        justifyContent={"space-around"}
      >
        <Stack direction={"column"} justifyContent={"space-between"}>
          <p>Start Date</p>
          <TextField
            type="date"
            onChange={handleChangeInput}
            name="startDate"
            value={formaData?.startDate || ""}
          />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>End Date Date</p>
          <TextField
            value={formaData?.endDate || ""}
            type="date"
            onChange={handleChangeInput}
            name="endDate"
          />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Merchant TID</p>
          <TextField
            type="text"
            onChange={handleChangeInput}
            label="Merchant TId"
            name="merchantTransactionId"
            value={formaData?.merchantTransactionId || ""}
          />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Payment Status</p>
          <FormControl
            sx={{
              minWidth: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Payment Status
            </InputLabel>
            <Select
              label="paymentStatus"
              name="status"
              onChange={handleChangeInput}
              value={formaData.status || ""}
            >
              <MenuItem value={"Success"}>Success</MenuItem>
              <MenuItem value={"Failed"}>Failed</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          background: "white",
          paddingInline: "20px",
          paddingBlock: "20px",
        }}
        direction={"row"}
        gap={2}
        alignItems={"end"}
        justifyContent={"space-around"}
      >
        <Stack direction={"column"} gap={1}>
          <p>Payment Method</p>
          <FormControl
            sx={{
              minWidth: "200px",
            }}
          >
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="paymenthod"
              name="paymentMethod"
              onChange={handleChangeInput}
              value={formaData.paymentMethod || ""}
            >
                 <MenuItem value={"Manual"}>Manual</MenuItem>
                 <MenuItem value={"Deduction"}>Deduction</MenuItem>
              <MenuItem value={"Refund"}>Refund</MenuItem>
              <MenuItem value={"Cashback"}>Cashback</MenuItem>
              <MenuItem value={"CARD"}>CARD</MenuItem>
              <MenuItem value={"UPI"}>UPI</MenuItem>
              <MenuItem value={"NETBANKING"}>NETBANKING</MenuItem>
              
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Campaign Id</p>
          <TextField
            type="text"
            onChange={handleChangeInput}
            label="Campaign Id"
            name="campaignId"
            value={formaData?.campaignId || ""}
          />
        </Stack>
        <Stack direction={"column"} gap={1}>
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>
          <Button variant="contained" sx={{
            width:200,
          }} onClick={handlereset}>
            Reset
          </Button>
        </Stack>
      </Stack>
      <br />
      <Box
        sx={{
          background: "white",
          paddingInline: "10px",
          paddingBlock: "20px",
          borderRadius: "10px",
        }}
      >
        <Table data={list} column={columns} totalPages={totalPages}/>
      </Box>
    </div>
    {filterLoading && <CoverLoader/>}
    <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
    </>
  );
};

export default UserTransaction;
