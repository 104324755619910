import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authAxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const filterUserAction = createAsyncThunk(
    'filter-users',
    async (data, { rejectWithValue }) => {
        try {
         
            const res = await authAxios.post(`/filter-user`,{...data} )
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const filterUserSlice = createSlice({
    name: 'filter-users',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(filterUserAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(filterUserAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(filterUserAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const filterUserReducer = filterUserSlice.reducer