import { Stack, Typography } from "@mui/material";
import React from "react";
import { AiOutlineLogout } from "react-icons/ai";

const UpperHeader = () => {
  return (
    <Stack
    direction={"row"}
    justifyContent={"flex-end"}
    gap={1}
      sx={{
        width: "100%",
        background: "white",
        position: "fixed",
        right: 0,
        zIndex: 1,
        top: 0,
        paddingInline: 3,
        paddingBlock: 2,
        borderRadius: "10px 0px 0px 10px",
        // boxShadow:"0px 20px 20px rgba(0,0,0,0.3)"
      }}
    >
      <Typography>Welcome ,{localStorage.getItem("name") || ""}</Typography>
      <AiOutlineLogout
      onClick={()=>{
        localStorage.clear()
        window.location.href ='/'
      }}
        style={{
          color: "red",
          fontSize: "1.5rem",
          cursor:"pointer",
          fontWeight:500
        }}
      />
    </Stack>
  );
};

export default UpperHeader;
