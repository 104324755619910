import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <div style={{
      width:"100%",
      background:"red",
      height:"100vh"
    }}>
        <Dialog open ={true}>
            <DialogContent>
            <CircularProgress />
            </DialogContent>
        </Dialog>
    </div>
  )
}

export default Loader