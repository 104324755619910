import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Loader from "../../components/Loader";
  import Toast from "../../components/Toast";
import { createNewPackageAction } from "../../redux/meta/createNewPackage";
import { getAllPackageAction } from "../../redux/meta/getAllPackages";
  
  const CreateNewPackage = ({ open = false, setOpen }) => {
      const [snake,setSnake] = useState(false);
    const [formdata, setFormdata] = useState({});
    const {loading,status,message} = useSelector(state=>state.createPackage)
    const dispatch = useDispatch();
  
    const handleInputChange = (e) => {
      const { value, name } = e.target;
      setFormdata((prev) => ({ ...prev, [name]: value }));
    };
  
    useEffect(() => {
      if (formdata) {
        const calculateAmount =
          formdata.amount - (formdata.discount / 100) * formdata.amount;
        setFormdata((prev) => ({ ...prev, finalAmount: calculateAmount }));
      }
    }, [formdata?.amount,formdata?.discount]);
  
    const handleUpdate = async () => {   
        console.log(formdata)
      const packageSave = await dispatch(createNewPackageAction(formdata))
      if(packageSave?.payload?.status === 200){
        await dispatch(getAllPackageAction())
      }
      
      setSnake(true)
      setOpen(false)
    };
    return (
      <>
  
      <div>
        <Dialog fullWidth open={open}>
          <DialogTitle>Update Package Details</DialogTitle>
          <DialogContent>
            <Stack
              gap={1}
              sx={{
                paddingBlock: 2,
              }}
            >
                <TextField
                type="text"
                onChange={handleInputChange}
                value={formdata?.packageName}
                name="packageName"
                size="small"
                label="Enter Name"
              />
              <TextField
                type="number"
                onChange={handleInputChange}
                value={formdata?.views}
                name="views"
                size="small"
                label="Enter Views"
              />
              <TextField
                type="number"
                onChange={handleInputChange}
                value={formdata?.amount}
                name="amount"
                size="small"
                label="Enter Raw Amount"
              />
              <TextField
                type="number"
                value={formdata?.finalAmount}
                name="finalAmount"
                disabled
                size="small"
                label="Final Amount"
              />
              <TextField
                type="number"
                onChange={handleInputChange}
                value={formdata?.discount}
                name="discount"
                size="small"
                label="Enter Discount"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button disabled = {loading} variant="contained" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* {loading && <Loader/>} */}
      <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
      </>
    );
  };
  
  export default CreateNewPackage;
  