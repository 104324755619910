import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children, redirectPath = '/' }) => {
  const isAuthenticated = !!localStorage.getItem('token');

  return isAuthenticated ? (
    <>
      {children}
    </>
  ) : (
    <Navigate to={redirectPath} />
  );
};

export default ProtectedRoutes;
