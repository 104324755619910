import axios from 'axios'
const token = localStorage.getItem("token")
const authAxios = axios.create({
    // baseURL:"http://localhost:4000/api/v1/affiliate-admin",
    baseURL:"https://backendadmin.proaffiliate.io/api/v1/affiliate-admin",
    headers:{
        Authorization:`Bearer ${token}`
    }
});

authAxios.interceptors.response.use((config)=>{return config},(error) => {
    let {
      response: {
        data: {message },
      },
    } = error;
    if(message === "jwt malformed"){
      // window.alert("Your Session Expired")
      // localStorage.clear()
      // window.location.href = '/'
    }
    return Promise.reject(error);
  })

  export default authAxios;