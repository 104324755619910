import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { getAllCampaignReducer } from "./campaign/getAllCampaign";
import manageBodyContent from './styles/bodyContent'
import { getSingleCampaignReducer } from "./campaign/getSingleCampaign";
import { approveCampaignReducer } from "./campaign/approveCampaign";
import { getAllUserReducer } from "./user/getAllUser";
import { getDashboardStatsReducer } from "./home/getDashboardStats";
import { getWeeklyRegistrationReducer } from "./home/getWeeklyRegistration";
import { getAllPackageReducer } from "./meta/getAllPackages";
import { getSinglePackageReducer } from "./meta/getSinglePackage";
import { updatePackageReducer } from "./meta/updatePackage";
import { createNewPackageReducer } from "./meta/createNewPackage";
import { filterUserReducer } from "./user/filterUser";
import { getAllPostbackReducer } from "./postback/getAllPostback";
import { loginReducer } from "./auth/login";
import { getAllTransactionReducer } from "./payment/getAllTransactions";
import { addAmountToUserReducer } from "./user/addAmount";
import { rejectCampaignReducer } from "./campaign/rejectCampaign";
import { tablePaginationReducer } from "./table/table";
import { userPaymentTransactionReducer } from "./user/userTransaction";

const rootReducers = combineReducers({
    // auth
    loginUser:loginReducer,
    // campaign
allCampaign:getAllCampaignReducer,
singleCampaign:getSingleCampaignReducer,
approveCampaign:approveCampaignReducer,
rejectCampaign:rejectCampaignReducer,

// dashboard
dashboardStats:getDashboardStatsReducer,
weeklyRegistration:getWeeklyRegistrationReducer,
// users
allUsers:getAllUserReducer,
filterUser:filterUserReducer,
addAmount:addAmountToUserReducer,
userPaymentTransaction:userPaymentTransactionReducer,

// css
bodyContent:manageBodyContent,
// meta
allPackage:getAllPackageReducer,
singlePackage:getSinglePackageReducer,
updatePackage:updatePackageReducer,
createPackage:createNewPackageReducer,

// post backs
allPostbacks:getAllPostbackReducer,

// payment
paymentTransaction:getAllTransactionReducer,

// table
tablePagination:tablePaginationReducer
})

const store = configureStore({
    reducer:rootReducers
})

export default store