import { createTheme } from "@mui/material/styles";

export const  theme =  createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Inter', 
        },
    },
    palette: {
        primary: { main: '#071D45' },
        error: { main: '#A70100' },
    },
    components:{
    MuiButton:{
        styleOverrides:{
            contained:{
                textTransform:"none",
                paddingInline:"10px",
                fontSize:17,
            },
        }
    },

    }
})