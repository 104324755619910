import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { addAmountToUserAction } from "../../redux/user/addAmount";

const AddAmount = ({ open, setOpen }) => {
  const [formData, setFormData] = useState({});
  const [snake, setSnake] = useState(false);
const [isDisbaled,setIsDisabled] = useState(false)
  const { loading, status, message } = useSelector((state) => state.addAmount);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const addAmount = async () => {
    if (
      Object.keys(formData).length === 0 ||
      Object.values(formData).length === 0
    ) {
      return;
    }
    setIsDisabled(true)
    await dispatch(addAmountToUserAction({ formData }));
    setIsDisabled(false)
    setSnake(true)
    setOpen(false)
  };

  const deductAmount = async () => {
    if (
      Object.keys(formData).length === 0 ||
      Object.values(formData).length === 0
    ) {
      return;
    }
    setIsDisabled(true)
    formData.deduction = true;
    await dispatch(addAmountToUserAction({ formData }));
    setIsDisabled(false)
    setSnake(true)
    setOpen(false)
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Or Deduct Amount</DialogTitle>
        <DialogContent>
          <Box p={3} width="100%" display="flex" flexDirection="column" gap={2}>
            <TextField
              onChange={handleChange}
              type="text"
              fullWidth
              value={formData.userId}
              name="userId"
              label="Enter User Id"
            />
            <TextField
              onChange={handleChange}
              type="number"
              value={formData.amount}
              fullWidth
              name="amount"
              label="Enter Amount"
            />
          </Box>
        </DialogContent>
        <DialogActions>
        <Button  disabled = {isDisbaled} variant="contained" onClick={deductAmount}>
            Deduct
          </Button>
          <Button disabled = {isDisbaled} variant="contained" onClick={addAmount}>
            Add
          </Button>
          <Button onClick={()=>setOpen(false)} disabled = {isDisbaled} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <Loader />}
      <Toast
        message={message}
        status={status}
        open={snake}
        setOpen={setSnake}
      />
    </>
  );
};

export default AddAmount;
