import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePackageAction } from "../../redux/meta/getSinglePackage";
import { updatePackageAction } from "../../redux/meta/updatePackage";
import Loader from "../../components/Loader";
import { getAllPackageAction } from "../../redux/meta/getAllPackages";
import Toast from "../../components/Toast";

const UpdatePackage = ({ id, open = false, setOpen }) => {
    const [snake,setSnake] = useState(false);
  const [formdata, setFormdata] = useState({});
  const {loading,status,message} = useSelector(state=>state.updatePackage)
  const { data } = useSelector((state) => state.singlePackage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getSinglePackageAction(id));
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setFormdata(data);
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormdata((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (formdata) {
      const calculateAmount =
        formdata.amount - (formdata.discount / 100) * formdata.amount;
      setFormdata((prev) => ({ ...prev, finalAmount: calculateAmount }));
    }
  }, [formdata?.amount,formdata?.discount]);

  const handleUpdate = async () => {
    formdata.packageId = formdata._id
    await dispatch(updatePackageAction(formdata));
    await dispatch(getAllPackageAction())
    setSnake(true)
    setOpen(false)
  };
  return (
    <>

    <div>
      <Dialog fullWidth open={open}>
        <DialogTitle>Update Package Details</DialogTitle>
        <DialogContent>
          <Stack
            gap={1}
            sx={{
              paddingBlock: 2,
            }}
          >
             <TextField
                type="text"
                onChange={handleInputChange}
                value={formdata?.packageName}
                name="packageName"
                size="small"
                label="Enter Name"
              />
              
            <TextField
              type="number"
              onChange={handleInputChange}
              value={formdata?.views}
              name="views"
              size="small"
              label="Enter Views"
            />
            <TextField
              type="number"
              onChange={handleInputChange}
              value={formdata?.amount}
              name="amount"
              size="small"
              label="Enter Raw Amount"
            />
            <TextField
              type="number"
              value={formdata?.finalAmount}
              name="finalAmount"
              disabled
              size="small"
              label="Final Amount"
            />
            <TextField
              type="number"
              onChange={handleInputChange}
              value={formdata?.discount}
              name="discount"
              size="small"
              label="Enter Discount"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled = {loading} variant="contained" onClick={handleUpdate}>
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    {/* {loading && <Loader/>} */}
    <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
    </>
  );
};

export default UpdatePackage;
