import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  withStyles,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserAction } from "../../redux/user/getAllUser";
import { filterUserAction } from "../../redux/user/filterUser";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";
import AddAmount from "./AddAmount";
import Table from "../../components/table/Table";
import CoverLoader from "../../components/cover-loader/CoverLoader";

const User = () => {
  const [formaData, setFormData] = useState({});
  const [snake, setSnake] = useState(false);
  const [list, setList] = useState([]);
  const [totalPages,setTotaPages] = useState(1);
  const [addAmount, setAddAmount] = useState(false);

  // redux
  const dispatch = useDispatch();
  const {page,limit} = useSelector(state=>state.tablePagination);
  const { data,loading } = useSelector((state) => state.allUsers);
  const { status, message } = useSelector((state) => state.filterUser);

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // use effects

  useEffect(() => {
    if(Object.keys(formaData).length ===0 || Object.values(formaData).length ===0){
      dispatch(getAllUserAction({limit,page}));
    }
   
  }, [page,limit])

  useEffect(() => {
    if (data) {
      setList(data?.users);
      setTotaPages(data?.totalPages)
    }
  }, [data]);

  const handleFilter = async () => {
    const filteredData = await dispatch(filterUserAction(formaData));
    if (filteredData?.payload?.status === 200) {
      setList(filteredData?.payload?.data?.filterUser);
      setTotaPages(filteredData?.payload?.data?.totalPages)
    }

    setSnake(true);
  };

  const columns = [
    {
      property: "_id",
      headerName: "User Id",
      minWidth: 230,
    },
    {
      property: "fullName",
      headerName: "Name",
      minWidth: 150,
    },
    {
      property: "email",
      headerName: "Email",
      minWidth: 230,

    },
    {
      property: "verified",
      headerName: "Verified",
      minWidth: 120,

    },
    {
      property: "balance",
      headerName: "Balance",
      minWidth: 180,
      flex: 1,
    },
    {
      property: "createdAt",
      headerName: "Time",
      minWidth: 230,
      flex: 1,
    }
  ];
  return (
    <>
      <div className="container">
   
        <Card>
          <CardContent>
          <Stack direction="row"  alignItems="center" justifyContent="space-between">
            <h3>Users Data</h3>
            <Button variant="contained" onClick={()=>setAddAmount(true)}>Add Amount</Button>
            </Stack>
          </CardContent>
        </Card>
       
     
       
        <Stack
          sx={{
            width: "100%",
            background: "white",
            paddingInline: "20px",
            paddingBlock: "20px",
            borderRadius: "10px",
            mt:4
          }}
          direction={"row"}
          gap={2}
          alignItems={"end"}
          justifyContent={"space-around"}
        >
          <Stack direction={"column"} justifyContent={"space-between"}>
            <p>Start Date</p>
            <TextField
              type="date"
              onChange={handleChangeInput}
              name="startDate"
              value={formaData?.startDate}
            />
          </Stack>

          <Stack direction={"column"} gap={1}>
            <p>End Date Date</p>
            <TextField
              value={formaData?.endDate}
              type="date"
              onChange={handleChangeInput}
              name="endDate"
            />
          </Stack>

          <Stack direction={"column"} gap={1}>
            <p>Email Id</p>
            <TextField
              value={formaData?.email}
              type="email"
              onChange={handleChangeInput}
              name="email"
              label="Email Id"
            />
          </Stack>

          <Stack direction={"column"} gap={1}>
            <p>User Id</p>
            <TextField
              type="text"
              onChange={handleChangeInput}
              label="userId"
              name="userId"
              value={FormData?.userId}
            />
          </Stack>

          <Stack direction={"column"} gap={1}>
            <p>Verified</p>
            <FormControl
              sx={{
                minWidth: "200px",
              }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                label="Status"
                name="verified"
                value={formaData?.status}
                onChange={handleChangeInput}
              >
                <MenuItem value={"true"}>Verified</MenuItem>
                <MenuItem value={"false"}>Not Verified </MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>
        </Stack>

        <Box
          sx={{
            background: "white",
            paddingInline: "10px",
            paddingBlock: "20px",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <Table data={list} column={columns} totalPages={totalPages} redirect="_id" path="/user/transaction"/>
        </Box>
      </div>
      <AddAmount open={addAmount} setOpen={setAddAmount} />
      {loading &&  <CoverLoader />}
      <Toast
        message={message}
        status={status}
        open={snake}
        setOpen={setSnake}
      />
    </>
  );
};

export default User;
