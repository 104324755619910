import { Button, Stack, TextField, Typography, Box, Card, CardContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from '../../components/table/Table'
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getAllCampaignAction } from "../../redux/campaign/getAllCampaign";
import { useNavigate } from "react-router-dom";

const Campaign = () => {
  const dispatch = useDispatch();
  const [formaData, setFormData] = useState({});
  const { data, loading, message } = useSelector((state) => state.allCampaign);
  const navigate = useNavigate()
  const {page,limit} = useSelector(state=>state.tablePagination)
  useEffect(() => {
    if(Object.keys(formaData).length === 0 || Object.values(formaData).length ===0){
      dispatch(getAllCampaignAction({limit,page}));
    }
  
  }, []);

  const handleFilter = async()=>{
  await dispatch(getAllCampaignAction({formaData,limit,page}))
  }

  const columns = [
    {
      property: "userId",
      headerName: "User Id",
      minWidth: 230,
    },
    {
      property: "campaignId",
      headerName: "Campaign Id",
      minWidth: 120,
    },
    {
      property: "targetedViews",
      headerName: "Target Views",
      flex:1,
      minWidth: 120,
    },
    // {
    //   headerName: "Videos",
    //   minWidth: 120,
    //   flex:1,
    //   renderCell: (params) => {
    //     return <Typography>{params?.row?.videoInfo?.length}</Typography>;
    //   },
    // },

    {
      property: "status",
      headerName: "Status",
      minWidth: 120,
      flex:1
    },
    {
      property: "paymentStatus",
      headerName: "Payment Status",
      minWidth: 200,
    },
    {
      property: "createdAt",
      headerName: "Date",
      minWidth: 240,
    },

  ];

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    
    <div className="container">
      <Card>
        <CardContent>
          <h3>Campaign Data</h3>
        </CardContent>
      </Card>
      <br />
      <Stack
        sx={{
          width: "100%",
          background: "white",
          paddingInline: "20px",
          paddingBlock: "20px",
          borderRadius: "10px",
        }}
        direction={"row"}
        gap={2}
        alignItems={"end"}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
      >
        <Stack direction={"column"} justifyContent={"space-between"}>
          <p>Start Date</p>
          <TextField
            type="date"
            onChange={handleChangeInput}
            name="startDate"
            value={formaData?.startDate}
          />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>End Date Date</p>
          <TextField value = {formaData?.endDate} type="date" onChange={handleChangeInput} name="endDate" />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Campaign Id</p>
          <TextField
            type="text"
            onChange={handleChangeInput}
            label="Campaign Id"
            name="campaignId"
            value={FormData?.campaignId}
          />
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Status</p>
          <FormControl
            sx={{
              minWidth: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select label="Status" name="status" value={formaData?.status} onChange={handleChangeInput}>
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Under View"}>Under View</MenuItem>
              <MenuItem value={"In Progress"}>In Progress</MenuItem>
              <MenuItem value={"Completed"}>Completed</MenuItem>
              <MenuItem value={"Not Initiated"}>Not Initiated</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Deleted</p>
          <FormControl
            sx={{
              minWidth: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select label="Deleted" name="deleted" value={formaData?.deleted} onChange={handleChangeInput}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={"column"} gap={1}>
          <p>Payment Status</p>
          <FormControl
            sx={{
              minWidth: "200px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
            <Select
              label="paymentStatus"
              name="paymentStatus"
              onChange={handleChangeInput}
              value={formaData.paymentStatus}
            >
              <MenuItem value={"Success"}>Success</MenuItem>
              <MenuItem value={"Failed"}>Failed</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Button variant="contained" onClick={handleFilter}>Filter</Button>
      </Stack>
      <br />

      <Box
        sx={{
          background: "white",
          paddingInline: "10px",
          paddingBlock: "20px",
          borderRadius:"10px"
        }}
      >
        <Table data={data?.campaignData} column={columns} totalPages={data?.totalPages} path="/campaigns/details" redirect="_id"/>
      </Box>
    </div>
  );
};

export default Campaign;
