import { FaHome } from "react-icons/fa";
import { RiStickyNoteLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa6";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaMeta } from "react-icons/fa6";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { BsSignpostSplitFill } from "react-icons/bs";
const sidebarLinks = [{
    title:"Home",
    path:'/home',
    icon:<FaHome/>
}
,{
    title:"Meta",
    path:'/meta',
    icon:<FaMeta/>
},{
    title:"Users",
    path:'/users',
    icon:<FaUsers/>
},{
    title:"Campaigns",
    path:'/campaigns',
    icon:<RiStickyNoteLine/>
},{
    title:"Transaction",
    path:'/transactions',
    icon:<FaIndianRupeeSign/>
},
{
    title:"Postback",
    path:'/postbacks',
    icon:<BsSignpostSplitFill/>
},
]
export default sidebarLinks