import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CampaignPieChart from "./CampaignPieChart";
import RegistrationBarChart from "./RegistrationBarChart";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardStatsAction } from "../../redux/home/getDashboardStats";
import indianNumberFormatter from "../../helper/indianNumberFormatter";
import Sidebar from "../../components/Sidebar";

const Dashboard = () => {
  const { data } = useSelector((state) => state.dashboardStats);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardStatsAction());
  }, []);

  const boxStyle = {
    border: "0.5px solid rgba(0,0,0,0.2)",
    paddingInline: 3,
    paddingBlock: 3,
    borderRadius: 2,
    minWidth:200
  };
  return (
    <div className="container">
      <Card>
        <CardContent>
          <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
            <Box sx={boxStyle}>
              <h4>Total Earning 💰</h4>
              <Typography>
              ₹ {indianNumberFormatter(data?.totalEarning) || 0}
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <h4>Total Users 👥</h4>
              <Typography>
                {indianNumberFormatter(data?.totalUsers) || 0}
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <h4>Verified Users ✅</h4>
              <Typography>
                {indianNumberFormatter(data?.totalVerifiedUsers) || 0}
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <h4>Total Campaigns 📊</h4>
              <Typography>
                {indianNumberFormatter(data?.totalCampaigns) || 0}
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <h4>Live Campaigns 🔴</h4>
              <Typography>
                {indianNumberFormatter(data?.totalLiveCampaigns) || 0}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Card
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
            <Box sx={boxStyle}>
              <h4>Completed Campaigns ✌</h4>
              <Typography>
                {indianNumberFormatter(data?.totalCompletedCampaigns) || 0}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Card
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
            <Box
              sx={{
                width: "40%",
                border: "0.5px solid rgba(0,0,0,0.3)",
                borderRadius: 2,
                paddingInline: 1,
                paddingBlock: 1,
                // background:"red",
                textAlign: "center",
              }}
            >
              <Typography>Campaign Stats</Typography>
              <br />
              <CampaignPieChart
                totalCampaign={data?.totalCampaigns}
                liveCampaign={data?.totalLiveCampaigns}
                completedCampaign={data?.totalCompletedCampaigns}
              />
            </Box>
            <Box
              sx={{
                width: "60%",
                border: "0.5px solid rgba(0,0,0,0.3)",
                borderRadius: 2,
                paddingInline: 1,
                paddingBlock: 1,
                // background:"red",
                textAlign: "center",
              }}
            >
              <Typography>Registration Trend</Typography>
              <br />
              <RegistrationBarChart />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
