import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authAxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const getWeeklyRegistrationAction = createAsyncThunk(
    'weekly-registration',
    async (data, { rejectWithValue }) => {
        try {
         
            const res = await authAxios.get(`/weekly-registration` )
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getWeeklyRegistrationSlice = createSlice({
    name: 'weekly-registration',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getWeeklyRegistrationAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(getWeeklyRegistrationAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(getWeeklyRegistrationAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const getWeeklyRegistrationReducer = getWeeklyRegistrationSlice.reducer