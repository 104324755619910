import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackageAction } from "../../redux/meta/getAllPackages";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import PackageImage from "../../assets/package.svg";
import { MdAddComment } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import UpdatePackage from "./UpdatePackage";
import CreateNewPackage from "./CreateNewPackage";
const Packages = () => {
    const [packageId,setPackageId] = useState("");
    const [updatePackage,setUpdatePackage] = useState(false)
    const [newPackage,setNewPackage] = useState(false)
    const [deletePackage,setDeletePackage] = useState(false)
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.allPackage);

  useEffect(() => {
    dispatch(getAllPackageAction());
  }, []);
  return (
    <>
    <div className="container">
      <Card>
        <CardContent>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={2}
          >
            {data?.map((val, i) => (
              <Stack
                direction={"column"}
                gap={0.5}
                sx={{
                  border: "0.5px solid rgba(0,0,0,0.3)",
                  paddingInline: 4,
                  paddingBlock: 2,
                  borderRadius: 2,
                }}
              >
                <h4>{val.packageName}</h4>
                <img
                  style={{
                    width: "200px",
                  }}
                  src={PackageImage}
                  alt="offer"
                />
                <Typography>Views : {val.views}</Typography>
                <Typography>
                  Raw Amount : <del>₹ {val.amount}</del>{" "}
                </Typography>
                <Typography>Final Amount : ₹ {val.finalAmount}</Typography>
                <Typography>Discount : {val.discount} % OFF</Typography>
                <Stack direction={"row"} gap={1}>
                  <Button startIcon={<MdAddComment />} onClick={()=>{
                    setPackageId(val._id)
                    setUpdatePackage(true)
                  }} >Update</Button>

                  <Button startIcon={<MdDelete />}>Delete</Button>
                </Stack>
              </Stack>
            ))}

            <Stack direction={"column"} sx={{
                  border: "0.5px solid rgba(0,0,0,0.3)",
                  paddingInline: 4,
                  paddingBlock: 2,
                  borderRadius: 2,
                }}>
              <h4>Create New Package</h4>
              <img
                  style={{
                    width: "200px",
                  }}
                  src={PackageImage}
                  alt="offer"
                />
              <Button onClick={()=>{
                setNewPackage(true)
              }}>
               Create
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </div>
    <UpdatePackage id={packageId} open={updatePackage} setOpen = {setUpdatePackage}/>
    <CreateNewPackage open={newPackage} setOpen={setNewPackage} />
    </>
  );
};

export default Packages;
