import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../styles/auth.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { loginAction } from "../../redux/auth/login";
import { useNavigate } from "react-router-dom";

const loginSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});
const Login = ({ isAutheticated }) => {
  const [snake, setSnake] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, status, loading } = useSelector((state) => state.loginUser);

  useEffect(() => {
    if (isAutheticated) {
      navigate("/home");
    }
  }, [isAutheticated]);

  const handleLogin = async (data) => {
    const loginUser = await dispatch(loginAction(data));
    if (loginUser?.payload?.status === 200) {
      localStorage.setItem("token", loginUser?.payload?.data?.token);
      localStorage.setItem("email", loginUser?.payload?.data?.email);
      localStorage.setItem("name", loginUser?.payload?.data?.name);
      window.location.href ='/home'
      return;
    }
    setSnake(true);
  };
  return (
    <>
      <Stack
        className="auth-body"
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            minWidth: "500px",
            minHeight: "400px",
            background: "white",
            paddingInline: "20px",
            paddingBlock: "20px",
            borderRadius: "10px",
            textAlign: "center",
            boxShadow: "0px 20px 30px rgba(0,0,0,0.4)",
          }}
        >
          <h2>PRO- AFFILIATE ADMIN</h2>
          <br />
          <Typography>Login To Admin</Typography>
          <br />
          <form
            className="auth-form-container"
            onSubmit={handleSubmit(handleLogin)}
          >
            <TextField
               size="small"
              name="email"
              {...register("email")}
              variant="outlined"
              type="email"
              label="Email Id"
              fullWidth
              helperText={errors?.email?.message}
            />
            <TextField
              size="small"
              name="password"
              {...register("password")}
              type="password"
              label="Password"
              fullWidth
              helperText={errors?.password?.message}
            />
            <Button type="submit" variant="contained">
              Login
            </Button>
          </form>
        </Stack>
      </Stack>
      {loading && <Loader />}
      <Toast
        open={snake}
        message={message}
        status={status}
        setOpen={setSnake}
      />
    </>
  );
};

export default Login;
