import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./screens/auth/Login";
import ProtectedRoutes from "./components/ProtectecRoutes";
import Dashboard from "./screens/home/Dashboard";
import Sidebar from "./components/Sidebar";
import Campaign from "./screens/campaign/Campaign";
import { useSelector } from "react-redux";
import CampaignDetails from "./screens/campaign/CampaignDetails";
import User from "./screens/users/User";
import Payment from "./screens/payment/Payment";
import Packages from "./screens/meta/Packages";
import Meta from "./screens/meta/Meta";
import PostBack from "./screens/postback/PostBack";
import UpperHeader from "./components/UpperHeader";
import UserTransaction from "./screens/users/UserTransaction";

function App() {
  const { marginLeft } = useSelector((state) => state.bodyContent);
  const isAutheticated = localStorage.getItem("token");

  return (
    <div
      className="App"
      style={{
        marginLeft: marginLeft
          ? `${280}px`
          : !isAutheticated
          ? `${0}px `
          : `${80}px `,
      }}
    >
      <Router>
        <ProtectedRoutes>
          <Sidebar />
        </ProtectedRoutes>
        <ProtectedRoutes>
          <UpperHeader />
        </ProtectedRoutes>

        <Routes>
          <Route
            path="/home"
            element={<ProtectedRoutes>{<Dashboard />}</ProtectedRoutes>}
          />
          <Route
            path="/users"
            element={<ProtectedRoutes>{<User />}</ProtectedRoutes>}
          />
          <Route
            path="/meta"
            element={<ProtectedRoutes>{<Meta />}</ProtectedRoutes>}
          />
          <Route
            path="/packages"
            element={<ProtectedRoutes>{<Packages />}</ProtectedRoutes>}
          />
          <Route
            path="/campaigns"
            element={<ProtectedRoutes>{<Campaign />}</ProtectedRoutes>}
          />
          <Route
            path="/postbacks"
            element={<ProtectedRoutes>{<PostBack />}</ProtectedRoutes>}
          />
          <Route
            path="/transactions"
            element={<ProtectedRoutes>{<Payment />}</ProtectedRoutes>}
          />
          <Route
            path="/campaigns/details/:campaignId"
            element={<ProtectedRoutes>{<CampaignDetails />}</ProtectedRoutes>}
          />
          <Route
            path="/user/transaction/:userId"
            element={<ProtectedRoutes>{<UserTransaction />}</ProtectedRoutes>}
          />
          <Route path="/" element={<Login isAutheticated={isAutheticated} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
