import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPostbackAction } from "../../redux/postback/getAllPostback";
import Table from '../../components/table/Table'
import { Card, CardContent, Typography } from "@mui/material";
const PostBack = () => {
  const dispqatch = useDispatch();
  const { data, loading } = useSelector((state) => state.allPostbacks);
  useEffect(() => {
    dispqatch(getAllPostbackAction());
  }, []);

  const columns = [
    // {
    //     field: "sn",
    //     headerName: "S.No",
    //     minWidth: 230,
    //     renderCell: (index) =>
    //   index.api.getRowIndexRelativeToVisibleRows(index.row) + 1,
    //   },
    {
      field: "campaignId",
      headerName: "Campaign Id",
      minWidth: 230,
    },
    {
        field: "amount",
        headerName: "Amount",
        minWidth: 120,
      },
      {
        field: "transactionId",
        headerName: "Transaction Id",
        minWidth: 230,
      },
      {
        field: "videoId",
        headerName: "Video Id",
        minWidth: 120,
      },
      {
        field: "publisherUserId",
        headerName: "Publisher User Id",
        minWidth: 230,
      },
      {
        field: "createdAt",
        headerName: "Date & Time",
        minWidth: 230,
      },
  ];

  return (
    <div className="container">
        <Card>
            <CardContent>
                <h3>Postback Logs</h3>
            </CardContent>
        </Card>
      <Card sx={{
        marginTop:2
      }}>
        <CardContent>
          <Table data={data} column={columns}/>
        </CardContent>
      </Card>
    </div>
  );
};

export default PostBack;
