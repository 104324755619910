import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import sidebarLinks from "../helper/link";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBodyMargin } from "../redux/styles/bodyContent";

const Sidebar = () => {
  const [showMenu, setShowMenu] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBodyMargin(showMenu));
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: showMenu ? "280px" : "50px",
        background: "white",
        paddingBlock: "20px",
        transition: "0.3s",
        overflowY: "auto",
        whiteSpace: "nowrap",
        position: "fixed",
        left: 0,
        top: "0px",
        zIndex: 2,
      }}
    >
      <Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          whiteSpace="nowrap"
          paddingInline={2}
          sx={{
            minWidth: 60,
          }}
        >
          {showMenu && (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              Pro-Affiliate
            </Typography>
          )}

          <MdOutlineMenu
            onClick={() => {
              setShowMenu(!showMenu);
              dispatch(setBodyMargin(!showMenu));
            }}
            style={{
              cursor: "pointer",
              fontSize: "2rem",
            }}
          />
        </Stack>
      </Box>

      <br />
      {sidebarLinks.map((val, i) => (
        <Stack
          className="sidebar-link-div"
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-start"}
          direction={"row"}
          sx={{
            transition: "0.3s",
            width: "100%",
            cursor: "pointer",
            paddingInline: "10px",
            paddingBlock: "1px",
            height: "50px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: val.path === pathname ? "white" : "black",
            background: val.path === pathname ? "#f95959" : "transparent",
          }}
          onClick={() => navigate(val.path)}
        >
          <Stack
            sx={{
              fontSize: "1.8rem",
            }}
          >
            {val.icon}
          </Stack>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 19,
            }}
          >
            {val.title}
          </Typography>
        </Stack>
      ))}
    </div>
  );
};

export default Sidebar;
