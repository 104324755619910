import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCampaignAction } from "../../redux/campaign/getSingleCampaign";
import { useParams } from "react-router-dom";
import indianNumberFormatter from "../../helper/indianNumberFormatter";
import { approveCampaignAction } from "../../redux/campaign/approveCampaign";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";
import { rejectCampaignAction } from "../../redux/campaign/rejectCampaign";

let message;
let status;
const CampaignDetails = () => {
  const [snake, setSnake] = useState(false);
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.singleCampaign);
  const { loading } = useSelector((state) => state.approveCampaign);

  useEffect(() => {
    dispatch(getSingleCampaignAction(campaignId));
  }, []);

  const approveCampaign = async () => {
    const approve = await dispatch(approveCampaignAction({ campaignId }));
    status = approve?.payload?.status;
    message = approve?.payload?.message;
    setSnake(true);
  };

  const rejectCampaign = async () => {
    const reject = await dispatch(rejectCampaignAction({ campaignId }));
    status = reject?.payload?.status;
    message = reject?.payload?.message;
    setSnake(true);
  };
  return (
    <>
      <div className="container">
        <Card>
          <CardContent>
            <Stack direction={"row"} gap={2}>
              <Stack direction={"row"} gap={2}>
                <Typography>Please Approve The Campaign</Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={approveCampaign}
                >
                  Approve
                </Button>
              </Stack>
              <Stack
                direction={"row"}
                gap={2}
                sx={{
                  borderLeft: "1px solid black",
                  paddingInline: "10px",
                }}
              >
                <Typography>Please Delete The Campaign</Typography>
                <Button onClick={rejectCampaign} size="small" variant="contained" color="error">
                  Delete
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          direction={"row"}
          marginTop={2}
          gap={2}
          justifyContent={"space-between"}
        >
          <Card
            sx={{
              minWidth: 200,
            }}
          >
            <CardContent>
              <h4>Target Views 🚀</h4>
              <Typography>
                {indianNumberFormatter(data?.targetedViews)}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              minWidth: 200,
            }}
          >
            <CardContent>
              <h4>Achieved Views</h4>
              <Typography>
                {indianNumberFormatter(data?.achievedViews)}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              minWidth: 200,
            }}
          >
            <CardContent>
              <h4>Achieved Likes 👍</h4>
              <Typography>
                {indianNumberFormatter(data?.achievedLikes)}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              minWidth: 200,
            }}
          >
            <CardContent>
              <h4>Achieved Subscribers</h4>
              <Typography>
                {indianNumberFormatter(data?.achievedSubscribers)}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              minWidth: 200,
            }}
          >
            <CardContent>
              <h4>Achieved Comments</h4>
              <Typography>
                {indianNumberFormatter(data?.achievedComments)}
              </Typography>
            </CardContent>
          </Card>
        </Stack>

        <Card
          sx={{
            marginTop: 2,
          }}
        >
          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>
                <h4>Campaign Budget</h4>
                <Typography>₹ {indianNumberFormatter(data?.budget)}</Typography>
              </Box>
              <Box>
                <h4>Extra Features</h4>
                <Typography>Increase {data?.extraFeatures}</Typography>
              </Box>
              <Box>
                <h4>Promotion Link</h4>
                <Typography>
                  {data?.promotionLink || "Not Available"}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            marginTop: 2,
          }}
        >
          <CardContent>
            <h4>Target Countries</h4>
            <Stack
              direction={"row"}
              justifyContent={"space-evenly"}
              flexWrap={"wrap"}
            >
              {data?.targetCountries?.map((val, i) => (
                <Typography key={i}>{val}</Typography>
              ))}
            </Stack>
          </CardContent>
        </Card>

        <Card
          sx={{
            marginTop: 2,
          }}
        >
          <CardContent>
            <h4>Watch Linked Videos</h4>
            {data?.videoInfo?.map((val, i) => (
              <Stack
                key={i}
                direction={"row"}
                alignItems={"center"}
                gap={2}
                marginTop={2}
              >
                <Box>
                  <img src={val?.videoThumbnail} alt="video" />
                </Box>
                <Stack direction={"column"} alignItems={"flex-start"} gap={1}>
                  <Typography>{val?.title}</Typography>
                  <Stack direction={"row"} gap={1}>
                    <Typography>
                      Initial Views : {val?.initialViews} |
                    </Typography>
                    <Typography>
                      Initial Likes : {val?.initialLikes} |
                    </Typography>
                    <Typography>
                      Initial Comments : {val?.initialComments}
                    </Typography>
                  </Stack>
                  <Button
                    size="small"
                    href={`https://www.youtube.com/watch?v=${val.videoId}`}
                  >
                    Watch Video
                  </Button>
                </Stack>
              </Stack>
            ))}
          </CardContent>
        </Card>
      </div>
      <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={status}
      />
      {loading && <Loader />}
    </>
  );
};

export default CampaignDetails;
